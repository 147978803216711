@import "@css/shared";

.root {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	max-width: 1320px;
	margin: 0 auto;
	width: 100%;

	> h1 {
		text-align: center;
		font-family: Lora;
		font-weight: 500;
		font-size: 64px;
		line-height: 81.92px;
		margin-top: 75px;
		margin-bottom: 50px;
		@include max(991) {
			margin-top: 20px;
			margin-bottom: 20px;
			font-size: 44px;
			line-height: 1.2;
		}
	}

	> svg {
		margin: 0 auto;
		@include max(991) {
			width: 120px;
			height: 120px;
		}
	}

	.faqContent {
		display: flex;
		flex-direction: column;
		padding-right: 50px;
		overflow: auto;
		gap: 40px;
		max-height: 60vh;
		margin-top: 40px;
		margin-bottom: 40px;
		@include scrollStyle(#3B3060);
		@include max(991) {
			margin-top: 20px;
			margin-bottom: 20px;
			padding-right: 0;
			max-height: none;
			gap: 16px;
		}
	}

	.faqAnswer {
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.2s ease-out;
		font-family: Poppins;
		font-weight: 400;
		font-size: 14px;
		line-height: 21px;
		color: #cfcfcf;

		strong {
			color: #FFF;
		}

		.ckContent {
			padding: 0 34px 34px 34px;
			@include max(991) {
				padding: 0 24px 24px 24px;
			}
		}
	}
	.activeIcon {
		color: #CC78FF;
		font-size: 30px
	}

	.faqItem {
		background-color: #3B3060;
		border-radius: 10px;

		&.active .faqAnswer {
			max-height: 3000px;
		}
	}

	.faqQuestion {
		width: 100%;
		background-color: transparent;
		border: 0;
		text-align: left;
		padding: 34px;
		font-family: Poppins;
		font-weight: 400;
		font-size: 24px;
		line-height: 36px;
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		@include max(991) {
			font-size: 20px;
			line-height: 24px;
			padding: 24px;
		}
	}

}
