@import "../shared";

.favorites {
    cursor: pointer;
}

.headerMobile {
    display: flex;
    @include min(992) {
        display: none;
    }

}

.headerDesktop {
    display: flex;
    @include max(991) {
        display: none;
    }
}

.favoritesRoot {
    position: relative;
    display: flex;
    align-items: center;

    .overBadge {
        padding: 16px;
        cursor: pointer;
        background-color: transparent;
        border: none;
    }

    .loadingSpin {
        color: #ffffff;
        font-size: 14px;
        margin: 16px 0;
    }

    .favoritesContent {
        position: absolute;
        top: 100%;
        width: 350px;
        max-width: calc(100vw - 8px);
        right: 0;
        background-color: #423072;
        box-shadow: 0 34px 80px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        padding: 8px;
        display: flex;
        flex-direction: column;

        @include max(400) {
            position: fixed;
            right: 8px;
        }
    }

    .scrollContent {
        max-height: 50vh;
        min-height: 200px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        padding-right: 8px;
        gap: 4px;
        @include scrollStyle(#cc78ff);
    }

    .noFavorites {
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        display: flex;
    }

    .cardBody {
        display: block;
        position: relative;
        box-shadow: 0 34px 80px rgba(0, 0, 0, 0.08);
        background-color: #30254c;
        padding: 6px 16px 6px 6px;
        border-radius: 8px;
        font-family: var(--font-poppins);
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 19px;
        cursor: pointer;
    }

    .remove {
        position: absolute;
        right: 4px;
        top: 4px;
    }

    .cardHeader {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;

        .cardHeaderId {
            span {
                color: #cd7afe;
            }
        }
    }

    .status {
        padding: 0;
        border-radius: 0;
        border-width: 0;
        line-height: 16px;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            bottom: 1px;
            height: 2px;
            background-color: var(--statusColor);
        }
    }

    .originImg {
        display: block;
    }

    .cardPropsList {
        display: flex;
        list-style: none;
        padding-left: 0;
        flex-wrap: wrap;
        gap: 2px 4px;
        margin-bottom: 0;

        li {
            color: #cfcfcf;
            gap: 3px;
            display: flex;
            flex-wrap: wrap;

            span {
                color: #ffffff;
            }
        }
    }
}

.favoritesBadge {
    position: relative;
    display: flex;
    align-items: center;
}

:global(.site-layout).content {
    flex-grow: 1;
    padding: var(--headerHeight) 16px 0;
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    overflow: hidden;
    background-color: var(--bodyBg);
    color: var(--color);
    @include max($tabletSwitch) {
        padding-top: var(--headerContentHeight);
    }
    @include max($mobileSwitch) {
        padding: var(--headerContentHeight) 8px 0;
    }
}

.listingsRoot > .content {
    padding: var(--headerHeight) 16px 0;
    overflow: unset;
    @include max($tabletSwitch) {
        padding-top: var(--headerContentHeight);
    }
    @include max($mobileSwitch) {
        padding: var(--headerContentHeight) 8px 0;
    }
}

.offerRoot > .content {
    overflow: unset;
}

.resortChartsRoot > .content {
    padding-left: 0;
    padding-right: 0;
}

.resortsRoot > .content {
    overflow: unset;
    padding-top: var(--socialHeight);

    @include max(768) {
        background: linear-gradient(180deg, #5a3f71 0%, #513b77 50.52%, #271e42 100%, #271e42 100%);
    }
    @include max($tabletSwitch) {
        padding-top: 0;
    }
}

.homePageRoot > .content {
    padding-top: 0;
}

.hotDealsRoot > .content {
    overflow: unset;
}

.layout {
    min-height: 100vh;
}

.userSubMenu {
    float: right;
}

.spin {
    margin-bottom: auto;
    margin-top: auto;
    position: absolute;
}

.socialLinks {
    height: var(--socialHeight);
    background-color: #271e42;
    transition: .2s;
    display: flex;

    .sticky & {
        margin-top: calc(var(--socialHeight) * -1);
    }

    @include max(991) {
        display: none;
    }
}

.socialContainer {
    max-width: 1280px;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;

    a {
        line-height: 0;
        color: #CFCFCF;
        font-size: 0;
    }

    .mail {
        line-height: 1;
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
    }
}

.header {
    height: var(--headerHeight);
    background: var(--headerColor);
    backdrop-filter: blur(25px);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: 0.3s;
    z-index: 99;

    &.transparent:not(.sticky) {
        backdrop-filter: none;
        background-color: transparent;

        @include min($mobileSwitch + 1) {
            background: rgba(49, 49, 49, 0.39);
            backdrop-filter: blur(25px);
        }
    }

    &.sticky {
        height: var(--headerStickyHeight);

        .headerNav {
            height: var(--headerStickyHeight);
        }
    }

    .headerMenu {
        background-color: transparent;
        border-bottom: none;
        display: flex;
        align-items: center;
        margin-left: auto;
        color: #ffffff;
        font-size: 16px;
        line-height: 28px;
        list-style: none;
        margin-bottom: 0;
        margin-right: 0;
        padding-left: 0;

        .headerMenuItem {
            cursor: pointer;
        }

        .headerMenuItemLink {
            font-size: 16px;
            line-height: 28px;
            color: #ffffff;
            padding: 23px 34px;

            @include max(1200) {
                padding: 23px;
            }
        }
    }

    .subMenu {
        list-style: none;
    }

    @include max($mobileSwitch) {
        .logo {
            width: 52px;
            height: auto;
        }
    }
    @include max($tabletSwitch) {
        height: var(--headerContentHeight);
    }
}

.userIcon {
    color: #ffffff;
    cursor: pointer;
    font-size: 16px;
    padding: 16px;
    min-width: 100px;
}

.signUpButton {
    background-color: #423072;
    color: #fff;
    border-radius: 8px;
    border: #3a49e0;
    padding: 13px 20px;
    align-self: center;
    cursor: pointer;
    @media (max-width: 400px) {
        .freeText {
            display: none;
        }

    }
}

.signInButton {
    background: transparent;
    border: none;
    cursor: pointer;
    margin-left: 4px;
    padding: 10px 8px;
    color: #fff;
    font-size: 20px;
}

.userPopover {
    :global(.ant-popover-inner) {
        background-color: #2b2741;
    }

    .subMenu {
        list-style: none;
        padding: 0;
        margin: 0;

        .headerMenuItem {
            line-height: 24px;
            cursor: pointer;

            & + .headerMenuItem {
                margin-top: 8px;
            }
        }

        .headerMenuItem,
        .headerMenuItemLink {
            color: #f0f2f5;

            &:hover,
            &:focus {
                color: #f0f2f5;
            }
        }
    }

    &:global(.ant-popover .ant-popover-content) {
        :global(.ant-popover-arrow) {
            border-color: #2b2741;
        }

        :global(.ant-popover-arrow-content) {
            background-color: #2b2741;
        }
    }
}

.overSocialLogin {
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;

    button {
        margin-left: 8px;

        :global(.anticon) {
            margin-right: 0;
        }
    }
}

.headerNav {
    display: flex;
    align-items: center;
    height: var(--headerContentHeight);
    max-width: 1280px;
    width: 100%;
    margin: auto;
    transition: height .2s;

    @include max(1256) {
        padding: 0 16px;
    }
}

.headerMenuToggleBtn {
    color: #ffffff;
    margin-left: auto;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;

    .transparent:not(.sticky) & {
        color: #cc78ff;
    }
}

.asideMenuDrawer {
    :global(.ant-drawer-content-wrapper) {
        background-color: rgba(49, 49, 49, 0.79);
        backdrop-filter: blur(37px);
        border-radius: 8px;
    }

    :global(.ant-drawer-content) {
        background-color: transparent;
    }

    :global(.ant-drawer-body) {
        padding: 30px;
    }

    :global(.ant-drawer-mask) {
        background-color: transparent;
    }

    .socialContainer {
        justify-content: center;
        gap: 16px;

        .mail {
            justify-content: center;
            width: 100%;
        }
    }

    .headerMenu,
    .subMenu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        list-style: none;
        margin-bottom: 0;
        font-size: 16px;
        line-height: 33px;
        padding-left: 18px;

        .headerMenuItem {
            color: #cfcfcf;
            width: 100%;
            margin-bottom: 35px;
            cursor: pointer;
        }

        .headerMenuItemLink {
            color: #cfcfcf;
            text-decoration: none;
        }
    }

    .subMenu {
        margin-bottom: 38px;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-image: linear-gradient(90.08deg, #cc78ff 14.15%, rgba(204, 120, 255, 0) 83.94%);
        }
    }
}

.headerStickyContainer {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 99;
}

.headerInject {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
}

.footer {
    margin-top: auto;
    background-color: #2b2741;
    padding-top: 55px;
    padding-bottom: 30px;
}

.footerContent {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;

    @include max(1267) {
        padding: 0 16px;
    }
}

.footerTopLevel {
    display: flex;
    justify-content: space-between;

    @include max(678) {
        flex-direction: column;
        align-items: center;
    }
}

.footerLinks {
    display: flex;
    justify-content: center;
    flex-shrink: 0;

    @include max(768) {
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}

.footerLinksGroup {
    display: flex;
    flex-direction: column;

    & + & {
        margin-left: 50px;
    }

    @include max(768) {
        margin: 20px 20px;

        & + & {
            margin-left: 20px;
        }
    }

    p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #ffffff;
        margin-bottom: 10px;
        margin-top: 0;
    }

    a {
        font-weight: 300;
        font-size: 12px;
        line-height: 32px;
        letter-spacing: 0.5px;
        color: #cdcdcd;
        margin-bottom: 6px;

        &:hover {
            color: #fff;
        }
    }
}

.footerTopLevelRight {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 314px;
    text-align: right;

    @include max(678) {
        margin-top: 20px;
        margin-left: 0;
        text-align: center;
        align-items: center;
    }
}

.footerTopLevelRightText {
    margin-top: 30px;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.47px;
    font-family: var(--font-poppins);
    color: #ffffff;
}

.footerBottomLevel {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 110px;
    flex-wrap: wrap;

    @include max(425) {
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
}

.footerBottomLevelRights {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #ffffff;
}

.footerBottomLevelSocial {
    flex-shrink: 0;
    display: flex;
    gap: 20px;
    @include max(425) {
        margin-bottom: 20px;
    }

    a {
        color: white;
        font-size: 0;
    }
}

.loginModalWrap {
    overflow: auto;
}

.loginModal:global(.ant-modal) {
    max-width: calc(100vw - 36px);

    .closeIcon {
        top: 6px;
        position: relative;

        svg {
            width: 24px;
            height: 24px;
        }
    }

    :global(.ant-modal-body) {
        display: flex;
        padding: 18px;
    }

    :global(.ant-modal-content) {
        // This is breaking iOS in edge cases
        // filter: drop-shadow(0px 34px 80px rgba(0, 0, 0, 0.08));
        border-radius: 15px;
        background-color: #423072;
    }

    :global(.ant-modal-close) {
        color: #ffffff;
    }

    .loginModalLogo {
        width: 34px;
        height: 24px;
        margin-left: 5px;
    }

    .leftSide {
        color: #ffffff;
        display: flex;
        flex-direction: column;
        background-color: #30254c;
        width: 430px;
        margin: -18px 0 -18px -18px;
        padding: 28px;
        border-radius: 15px 0 0 15px;
        gap: 23px;

        .leftSideContent {
            padding: 0 30px;
            font-family: var(--font-poppins);
            font-size: 16px;
            line-height: 140%;
            letter-spacing: 0.0583333px;
            color: #cfcfcf;
        }

        .signupLeftSideContent {
            padding: 0 30px;
        }

        .signupTitle {
            font-family: var(--font-lora);
            font-size: 24px;
            font-weight: 500;
            line-height: 35px;
            letter-spacing: 0.42px;
            text-align: left;
            padding-bottom: 24px;
            border-bottom: 1px solid #CC78FF
        }

        .tierTitle {
            font-family: var(--font-poppins);
            font-size: 40px;
            font-weight: 500;
            line-height: 60px;
            text-align: center;
            padding-top: 16px;
            margin-bottom: 14px;
        }

        .tierInfo {

            ul {
                display: flex;
                flex-direction: column;
                gap: 16px;
                font-family: var(--font-poppins);
                font-size: 16px;
                font-weight: 400;
                line-height: 22.4px;
                letter-spacing: 0.05833333358168602px;
                text-align: left;
                color: #fff;
                padding-left: 0;
            }

            li {
                background: url("/images/check.svg") no-repeat 0 0 transparent;
                list-style: none;
                margin: 0;
                padding: 0 0 1px 33px;
            }
        }

        .title {
            font-family: var(--font-lora);
            font-weight: 500;
            font-size: 24px;
            line-height: 35px;
            letter-spacing: 0.416667px;
            color: #ffffff;
            margin-top: 0;
            margin-bottom: 23px;
        }
    }

    .rightSide {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        min-height: 350px;
        max-width: 100%;
        padding-top: 35px;

        .loginModalLogo {
            position: absolute;
            left: 18px;
            top: 18px;
            margin-top: 0;
        }
    }

    .rightSideTitle {
        font-weight: 600;
        font-size: 24px;
        line-height: 18px;
        letter-spacing: 0.416667px;
        color: #ffffff;
        margin-bottom: 14px;
        margin-top: 16px;
    }

    .rightSubSideTitle {
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.0583333px;
        color: #cfcfcf;
        margin-bottom: 30px;
    }

    .forgotPassword,
    .forgotPassword a {
        text-align: center;
        font-size: 12px;
        line-height: 21px;
        letter-spacing: 0.0583333px;
        color: #cfcfcf;
        margin-top: 12px;
    }

    .forgotPassword a {
        text-decoration: underline;
    }
}

.resetPassword {
    display: flex;

    .rightSide {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        min-height: 350px;
        max-width: 100%;
        padding-top: 35px;

        :global(.ant-input-affix-wrapper),
        :global(.ant-input-affix-wrapper:focus),
        :global(.ant-input-affix-wrapper:hover),
        :global(.ant-input-suffix) {
            background-color: #271e42;
            border-color: transparent;
            padding: 0;
            box-shadow: none;

            :global(.anticon) {
                color: #fff;
            }
        }
    }

    .rightSideTitle {
        font-weight: 600;
        font-size: 24px;
        line-height: 18px;
        letter-spacing: 0.416667px;
        color: #ffffff;
        margin-bottom: 14px;
    }

    .rightSubSideTitle {
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.0583333px;
        color: #cfcfcf;
        margin-bottom: 30px;
    }
}

.signInPanelRoot {
    display: flex;
    flex-direction: column;
    width: 356px;
    max-width: 100%;

    .subscribeField {
        > :global(.ant-row) {
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: flex-start;
        }
        :global(.ant-form-item-control) {
            flex-basis: 32px;
        }
        :global(.ant-form-item-label) {
            order: 2;
            flex-basis: calc(100% - 32px);
        }
    }

    .signInPanelBtn {
        @extend .btnFilled;
        margin-bottom: 24px;
        height: 42px;
        width: 100%;
    }

    .signInPanelDivider {
        height: 24px;
        display: flex;
        align-items: center;
        color: #ffffff;
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
        font-family: var(--font-poppins);

        &:before,
        &:after {
            content: "";
            flex-grow: 1;
            height: 1px;
            background-color: #cc78ff;
        }

        &:before {
            margin-right: 18px;
        }

        &:after {
            margin-left: 18px;
        }
    }

    .signInPanelOverIcon {
        display: flex;
        justify-content: center;
        padding-top: 10px;
    }

    .signInPanelIconBtn {
        background-color: transparent;
        font-size: 0;
        border: none;
        outline: none;
        cursor: pointer;

        + .signInPanelIconBtn {
            margin-left: 65px;
        }
    }

    .appleButton {
        width: 44px;
        height: 44px;
        background-color: #fff;
        border-width: 0;
        border-color: #000;
        border-radius: 10px;
        padding: 0;
        border-style: solid;
    }

    .signUpLine {
        font-family: var(--font-poppins);
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        margin-top: 20px;
        color: #ffffff;
        text-align: center;

        button {
            color: #cc78ff;
            background-color: transparent;
            border: none;
            cursor: pointer;
        }
    }
}

.passField {
    :global(.ant-input-password .ant-input-suffix) {
        position: absolute;
        right: 5px;
        top: 2px;
        bottom: 2px;
        width: 20px;
        background-color: transparent !important;
    }
}
